import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Alert,
  Modal,
  InputGroup,
  Container,
  Table,
} from "react-bootstrap";
import { fetchData } from "../../utils/fetchData";
import AddVatModal from "./AddVatModal";
import { PAGES } from "../../config/permissions";
import { withPermissions } from "../../components/hoc/withPermissions";

const SettingsForm = () => {
  const [settings, setSettings] = useState({
    tripCancellationThresholdHours: 1,
    fetchYitTripsToCancelIntervalMinutes: 10,
    activeUsersDisplayThresholdMinutes: 180,
    fetchTripsIntervalMinutes: 15,
    defaultPublisherFee: 5,
    defaultTakerFee: 5,
    vatHistory: [],
  });
  const [invalidFields, setInvalidFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showVatModal, setShowVatModal] = useState(false);
  const [newVat, setNewVat] = useState({ rate: "", effectiveDate: "" });

  // Fetch current settings from the server
  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        const response = await fetchData({ request: "config" }, "GET");
        const filteredSettings = {
          tripCancellationThresholdHours:
            response.tripCancellationThresholdHours ?? 1,
          fetchYitTripsToCancelIntervalMinutes:
            response.fetchYitTripsToCancelIntervalMinutes ?? 10,
          activeUsersDisplayThresholdMinutes:
            response.activeUsersDisplayThresholdMinutes ?? 180,
          fetchTripsIntervalMinutes: response.fetchTripsIntervalMinutes ?? 15,
          defaultPublisherFee: response.defaultPublisherFee ?? 5,
          defaultTakerFee: response.defaultTakerFee ?? 5,
          vatHistory: response.vatHistory || [],
        };
        setSettings(filteredSettings);
      } catch (error) {
        setErrorMessage("שגיאה בטעינת ההגדרות מהשרת");
        console.error("Error fetching settings:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  // Handle input changes
  const handleInputChange = (key, value) => {
    setSettings((prev) => ({
      ...prev,
      [key]: value,
    }));

    // Remove field from invalidFields if user corrects it
    setInvalidFields((prev) => prev.filter((field) => field !== key));
  };

  // Save updated settings to the server and call clear_config
  const saveSettings = async () => {
    setIsLoading(true);
    setShowConfirmation(false);
    try {
      const requestObject = {
        request: "config",
        ...settings,
      };
      await fetchData(requestObject, "PUT");
      await fetchData({ request: "config/clear_config" }, "POST");
      setSuccessMessage("ההגדרות נשמרו ואופסו בהצלחה!");
      setErrorMessage("");
      setInvalidFields([]);
    } catch (error) {
      const serverErrorMessage =
        error.response?.data?.message || error.message || "שגיאה כללית אירעה";

      // Extract invalid fields if available in response
      const invalidFieldKeys = error.response?.data?.invalidFields || [];
      setInvalidFields(invalidFieldKeys);
      setErrorMessage(serverErrorMessage);
      console.error("Error saving settings or clearing config:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const addVatRate = () => {
    if (!newVat.rate || !newVat.effectiveDate) {
      setErrorMessage('יש למלא את כל השדות של המע"מ החדש');
      return;
    }

    // הוספת הערך ל-UI בלבד
    const updatedVatHistory = [...settings.vatHistory, newVat];
    const sortedVatHistory = updatedVatHistory.sort(
      (a, b) => new Date(a.effectiveDate) - new Date(b.effectiveDate)
    );

    setSettings((prev) => ({
      ...prev,
      vatHistory: sortedVatHistory,
    }));

    setNewVat({ rate: "", effectiveDate: "" });
    setShowVatModal(false);
    setSuccessMessage(
      'אחוז מע"מ נוסף בהצלחה ב-UI. יש ללחוץ שמור כדי לשמור לשרת!'
    );
  };

  // Helper to determine if a field is invalid
  const isFieldInvalid = (key) => invalidFields.includes(key);

  return (
    <Container fluid className="p-4">
      <h2 className="font-weight-bold">עריכת הגדרות מערכת</h2>
      <p>
        כאן אפשר לשנות הגדרות של המערכת וממשק שלה מול מקורות חיצוניים, עדיף לא
        לשנות אם לא מבינים.
      </p>

      {/* Alerts */}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

      {/* Form */}
      <Form className="mt-3">
        <Form.Group controlId="tripCancellationThresholdHours" className="mb-3">
          <Form.Label>זמן לביטול נסיעה ללא נהג לפני מועד התייצבות</Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              value={settings.tripCancellationThresholdHours}
              min="1"
              className={
                isFieldInvalid("tripCancellationThresholdHours")
                  ? "border border-danger"
                  : ""
              }
              onChange={(e) =>
                handleInputChange(
                  "tripCancellationThresholdHours",
                  parseFloat(e.target.value)
                )
              }
            />
            <InputGroup.Text className="px-3">דקות</InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <Form.Group
          controlId="fetchYitTripsToCancelIntervalMinutes"
          className="mb-3"
        >
          <Form.Label>תדירות בדיקת נסיעות ללא נהג במערכת YIT</Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              value={settings.fetchYitTripsToCancelIntervalMinutes}
              min="10"
              className={
                isFieldInvalid("fetchYitTripsToCancelIntervalMinutes")
                  ? "border border-danger"
                  : ""
              }
              onChange={(e) =>
                handleInputChange(
                  "fetchYitTripsToCancelIntervalMinutes",
                  parseFloat(e.target.value)
                )
              }
            />
            <InputGroup.Text className="px-3">דקות</InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <Form.Group
          controlId="activeUsersDisplayThresholdMinutes"
          className="mb-3"
        >
          <Form.Label>זמן הצגת משתמשים פעילים</Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              value={settings.activeUsersDisplayThresholdMinutes}
              min="180"
              className={
                isFieldInvalid("activeUsersDisplayThresholdMinutes")
                  ? "border border-danger"
                  : ""
              }
              onChange={(e) =>
                handleInputChange(
                  "activeUsersDisplayThresholdMinutes",
                  parseFloat(e.target.value)
                )
              }
            />
            <InputGroup.Text className="px-3">דקות</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="fetchTripsIntervalMinutes" className="mb-3">
          <Form.Label>תדירות עדכון סטטוס נסיעות מול מערכת YIT</Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              value={settings.fetchTripsIntervalMinutes}
              min="1"
              className={
                isFieldInvalid("fetchTripsIntervalMinutes")
                  ? "border border-danger"
                  : ""
              }
              onChange={(e) =>
                handleInputChange(
                  "fetchTripsIntervalMinutes",
                  parseFloat(e.target.value)
                )
              }
            />
            <InputGroup.Text className="px-3">דקות</InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <div className="py-3">
          <hr />
        </div>
        <Form.Group controlId="defaultPublisherFee" className="mb-3">
          <Form.Label>
            ברירת המחדל של עמלת חברה
            <span className="font-weight-bold"> המציעה </span>
            באחוזים
          </Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              value={settings.defaultPublisherFee}
              min="1"
              className={
                isFieldInvalid("defaultPublisherFee")
                  ? "border border-danger"
                  : ""
              }
              onChange={(e) =>
                handleInputChange(
                  "defaultPublisherFee",
                  parseFloat(e.target.value)
                )
              }
            />
            <InputGroup.Text className="px-3">%</InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <Form.Group controlId="defaultTakerFee" className="mb-3">
          <Form.Label>
            ברירת המחדל של עמלת חברה
            <span className="font-weight-bold"> המבצעת </span>
            באחוזים
          </Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              value={settings.defaultTakerFee}
              min="1"
              className={
                isFieldInvalid("defaultTakerFee") ? "border border-danger" : ""
              }
              onChange={(e) =>
                handleInputChange("defaultTakerFee", parseFloat(e.target.value))
              }
            />
            <InputGroup.Text className="px-3">%</InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <AddVatModal
          newVat={newVat}
          showVatModal={showVatModal}
          addVatRate={() => addVatRate()}
          setShowVatModal={setShowVatModal}
          setNewVat={setNewVat}
        />
        <h4 className="mt-4">היסטוריית אחוזי מע"מ</h4>
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>אחוז מע"מ</th>
              <th>תאריך תחולה</th>
            </tr>
          </thead>
          <tbody>
            {settings.vatHistory.map((vat, index) => (
              <tr key={index}>
                <td>{vat.rate}%</td>
                <td>
                  {new Date(vat.effectiveDate).toLocaleDateString("he-IL")}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Form>

      {/* Buttons */}
      <div className="d-flex justify-content-end mt-4">
        <Button
          variant="primary"
          onClick={() => setShowConfirmation(true)}
          disabled={isLoading}
        >
          שמור
        </Button>
      </div>

      {/* Confirmation Modal */}
      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>אישור שמירה</Modal.Title>
        </Modal.Header>
        <Modal.Body>האם אתה בטוח שברצונך לשמור את השינויים?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmation(false)}
          >
            ביטול
          </Button>
          <Button variant="primary" onClick={saveSettings} disabled={isLoading}>
            שמור
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default withPermissions(SettingsForm, PAGES.SETTINGS);
